import { useEffect, useState } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { checkCookies, getCookie, removeCookies } from 'cookies-next'
import Image from 'next/image'
import styles from './Navbar.module.scss'
import {
  shopifyCartCookie,
  customerIdentityCookieName,
  VITALIVE_SERVER_URL,
  path,
  SHOW_BLOG,
} from '../../../constants/constants'
import { useCustomerDataContext } from '../../../contexts/CustomerDataContext'
import { refreshToken } from '../../../utilities/refreshTokens'
import Router from 'next/router'
import { createCheckout } from '../../../utilities/shopifyUtilites'
import { parseJson } from '../../../utilities/jsonUtils'
import cn from 'classnames'
import useWindowDimensions from "../../../customHooks/useWindowDimension";

export const Navbar = ({ currentPage, notFixedHeader }) => {
  const { t } = useTranslation('header')
  const [menuOpened, setMenuOpened] = useState(false)
  const [count, setCount] = useState(0)
  let userData = useCustomerDataContext()
  const { width } = useWindowDimensions()

  let _shopifyCookie = getCookie(shopifyCartCookie)

  _shopifyCookie = _shopifyCookie ? parseJson(_shopifyCookie) : null

  userData = userData.dataContext
    ? userData.dataContext.customerData
    : userData.customerData || userData

  useEffect(() => {
    setCount(_shopifyCookie?.countOfProducts || 0)
  }, [_shopifyCookie, checkCookies(shopifyCartCookie)])

  const signOut = async () => {
    let _identityCookie = getCookie(customerIdentityCookieName)
    _identityCookie = _identityCookie ? parseJson(_identityCookie) : null
    try {
      await axios.get(`${VITALIVE_SERVER_URL}/auth/logout`, {
        headers: {
          Authorization: `Bearer ${
            _identityCookie ? _identityCookie.accessToken : ''
          }`,
        },
      })
      removeCookies(customerIdentityCookieName, path)
      createCheckout()
      Router.push('https://shop.vitaliv.us/account/login')
    } catch (err) {
      Sentry.captureException(err)
      console.error('signOut', err)
      refreshToken(signOut)
    }
  }

  useEffect(()=> {
    window.onscroll = function() {scrollFunction()};
  }, [])


  function scrollFunction() {
    if (window.pageYOffset || document.documentElement.scrollTop) {
      document.getElementById("navbar").style.padding = "6px 0";
      document.getElementById("logo").style.width = "150px";
      document.getElementById("list").style.marginTop = "2px";
      document.getElementById("cart").style.top = "18px";
    } else {
      document.getElementById("navbar").style.padding = "11px 0 18px";
      document.getElementById("logo").style.width = "174px";
      document.getElementById("list").style.marginTop = "10px";
      document.getElementById("cart").style.top = "29px";
    }
  }

  const toggleMenu = () => {
    setMenuOpened((prevState) => !prevState)
  }

  return (
    <header

      className={cn(styles.siteHeader, {
        [styles.siteHeaderRelative]: notFixedHeader,
      })}
    >
      <nav className={`${styles.container} ${styles.siteHeaderRow}`}>
        <div className={styles.siteHeaderLogoMobile}>
          <Link href="/">
            <a >
              <img
                  className={styles.image}
                src="/images/logoNew.svg"
                alt="logo"
                width={110}
                height={30}
              />
            </a>
          </Link>
        </div>

        <div id='cart' className={styles.headerCartBlock}>
          <div className={`${styles.headerMenuAccount} ${styles.headerMenuAccount_navMenu}`}>

            {currentPage === 'account' ? (
                <div className={styles.headerLogin} onClick={signOut}>
                  <i />
                  <p>{t('sign-out')}</p>
                </div>
            ) : (
                <Link href={userData.id ? '/account' : 'https://shop.vitaliv.us/account/login'}>
                  <a className={styles.headerLogin}>
                    <i />
                    <p>
                      {userData.id
                          ? `${userData.firstName} ${(userData.lastName).slice(0,1)}.`
                          : t('hello, log in')}
                    </p>
                  </a>
                </Link>
            )}
          </div>

          {width > 764 &&
              <Link href="/cart">
                <a className={styles.headerCart}>
                  {' '}
                  <i/>
                  {count ? (
                      <span className={styles.count}>{count}</span>
                  ) : (
                      <span></span>
                  )}
                </a>
              </Link>
          }


        </div>

        <button
          className={menuOpened ? styles.mobileMenuOpened : styles.mobileMenu}
          onClick={toggleMenu}
        />
        <div className={menuOpened ? styles.wrapperMobileMenu : ''}>
          {menuOpened ? <p className={styles.menuTitle}>Menu</p> : null}
          <div className={styles.wrapperMobileMenuSecond}>
            <div
                id='navbar'
              className={
                menuOpened
                  ? styles.siteHeaderMenuPanelOpened
                  : styles.siteHeaderMenuPanel
              }
            >
              <div className={styles.siteHeaderLogo}>
                <Link href="/">
                  <a>
                    <span className={styles.headerLogoFull}>
                      <img
                        className={styles.logo}
                        id='logo'
                        src="/images/logoNew.svg"
                        alt="logo"
                        width={174}
                        height={61}
                      />
                    </span>
                    <span className={styles.headerLogoIcon}>
                      <img
                        src="/images/vitapoints_logo.svg"
                        alt="vitapoints_logo"
                        width={25}
                        height={24}
                      />
                    </span>
                  </a>
                </Link>
              </div>
              <div className={styles.mainMenu}>
                <ul id='list' className={styles.list}>
                  <li
                    className={
                      currentPage === 'shop' ? styles.activeMenuItem : ''
                    }
                  >
                    <Link href="https://shop.vitaliv.us/collections/all">
                      {t('shop')}
                    </Link>
                  </li>
                  <li
                    className={
                      currentPage === 'joinclub' ? styles.activeMenuItem : ''
                    }
                  >
                    <Link href="/vitalivclub">Vitaliv Club</Link>
                    {/*<Link href="/?joinclub=true">*/}
                    {/*  {t('vitaliv-club-header')}*/}
                    {/*</Link>*/}
                  </li>
                  <li
                    className={
                      currentPage === 'aboutus' ? styles.activeMenuItem : ''
                    }
                  >
                    <Link href="/aboutus">About us</Link>
                  </li>


                  <li
                    className={
                      currentPage === 'delivery' ? styles.activeMenuItem : ''
                    }
                  >
                    <Link href="/delivery">{t('delivery')}</Link>
                  </li>
                  {SHOW_BLOG ? (
                    <li
                      className={
                        currentPage === 'blog' ? styles.activeMenuItem : ''
                      }
                    >
                      <a href="https://blog.vitaliv.us">{t('blog')}</a>
                    </li>
                  ) : null}

                  <li
                      className={
                        currentPage === 'faq' ? styles.activeMenuItem : ''
                      }
                  >
                    <a href="/faq">{t('faq')}</a>
                  </li>

                  <li
                      className={
                        currentPage === 'contacts' ? styles.activeMenuItem : ''
                      }
                  >
                    <a href="/contacts">{t('contacts')}</a>
                  </li>
                </ul>
              </div>

              {/*<div*/}
              {/*  className={`${styles.headerMenuAccount} ${styles.headerMenuAccount_mobileMenu}`}*/}
              {/*>*/}
              {/*  {userData.id && (*/}
              {/*    <h3 className={styles.titleName}>*/}
              {/*      Hello, {userData.firstName}*/}
              {/*    </h3>*/}
              {/*  )}*/}

              {/*  <Link href="/cart">*/}
              {/*    <a className={`${styles.headerCart} ${styles.cartWrapper}`}>*/}
              {/*      {' '}*/}
              {/*      <i />*/}
              {/*      {count ? (*/}
              {/*        <span className={styles.count}>{count}</span>*/}
              {/*      ) : (*/}
              {/*        <span></span>*/}
              {/*      )}*/}
              {/*      <p className={styles.text}>my cart</p>*/}
              {/*    </a>*/}
              {/*  </Link>*/}

              {/*  {currentPage === 'account' ? (*/}
              {/*    <div className={styles.headerLogin} onClick={signOut}>*/}
              {/*      <i />*/}
              {/*      <p>{t('sign-out')}</p>*/}
              {/*    </div>*/}
              {/*  ) : (*/}
              {/*    <Link href="/account">*/}
              {/*      <a className={styles.headerLogin}>*/}
              {/*        <i />*/}

              {/*          {userData.id ? (*/}
              {/*              <p>*/}
              {/*                {t('my_account')}*/}
              {/*              </p>*/}
              {/*          ):*/}
              {/*              <p>{t('hello, log in')}</p>*/}
              {/*          }*/}
              {/*      </a>*/}
              {/*    </Link>*/}
              {/*  )}*/}
              {/*</div>*/}

            </div>
          </div>
          {menuOpened &&
              <div className={styles.mobileMenuFooter}>

                <div>
                  <a href="https://www.instagram.com/vitaliv.club/" target="_blank" rel="noreferrer" className={styles.socialLink}>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#4FAF4D"/>
                      <path d="M19.9973 16.6652C18.1611 16.6652 16.6626 18.1638 16.6626 20C16.6626 21.8362 18.1611 23.3348 19.9973 23.3348C21.8335 23.3348 23.332 21.8362 23.332 20C23.332 18.1638 21.8335 16.6652 19.9973 16.6652ZM29.9989 20C29.9989 18.6191 30.0114 17.2506 29.9338 15.8722C29.8563 14.2711 29.4911 12.8502 28.3203 11.6794C27.147 10.5061 25.7286 10.1433 24.1275 10.0658C22.7466 9.98824 21.3782 10.0007 19.9998 10.0007C18.6189 10.0007 17.2505 9.98824 15.8721 10.0658C14.271 10.1433 12.8501 10.5086 11.6793 11.6794C10.5061 12.8527 10.1433 14.2711 10.0658 15.8722C9.98824 17.2531 10.0007 18.6216 10.0007 20C10.0007 21.3784 9.98824 22.7494 10.0658 24.1278C10.1433 25.7289 10.5086 27.1498 11.6793 28.3206C12.8526 29.4939 14.271 29.8567 15.8721 29.9342C17.253 30.0118 18.6214 29.9993 19.9998 29.9993C21.3807 29.9993 22.7491 30.0118 24.1275 29.9342C25.7286 29.8567 27.1495 29.4914 28.3203 28.3206C29.4936 27.1473 29.8563 25.7289 29.9338 24.1278C30.0139 22.7494 29.9989 21.3809 29.9989 20V20ZM19.9973 25.131C17.1579 25.131 14.8664 22.8394 14.8664 20C14.8664 17.1606 17.1579 14.869 19.9973 14.869C22.8367 14.869 25.1282 17.1606 25.1282 20C25.1282 22.8394 22.8367 25.131 19.9973 25.131ZM25.3383 15.8572C24.6754 15.8572 24.14 15.3218 24.14 14.6589C24.14 13.9959 24.6754 13.4606 25.3383 13.4606C26.0013 13.4606 26.5366 13.9959 26.5366 14.6589C26.5368 14.8163 26.5059 14.9722 26.4458 15.1177C26.3856 15.2632 26.2974 15.3954 26.1861 15.5067C26.0748 15.618 25.9426 15.7062 25.7971 15.7664C25.6516 15.8265 25.4957 15.8574 25.3383 15.8572V15.8572Z" fill="white"/>
                    </svg>
                  </a>

                  <a href="https://www.facebook.com/VitalivClubUS/" target="_blank" rel="noreferrer">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20" r="20" fill="#4FAF4D"/>
                      <path d="M25.3614 13.0864H21.998C21.5994 13.0864 21.1565 13.6109 21.1565 14.3078V16.7365H25.3637L24.7274 20.2002H21.1565V30.5981H17.1871V20.2002H13.5859V16.7365H17.1871V14.6994C17.1871 11.7765 19.2149 9.40137 21.998 9.40137H25.3614V13.0864Z" fill="white"/>
                      <path d="M25.3614 13.0864H21.998C21.5994 13.0864 21.1565 13.6109 21.1565 14.3078V16.7365H25.3637L24.7274 20.2002H21.1565V30.5981H17.1871V20.2002H13.5859V16.7365H17.1871V14.6994C17.1871 11.7765 19.2149 9.40137 21.998 9.40137H25.3614V13.0864Z" fill="white"/>
                    </svg>
                  </a>

                </div>

                <div className={styles.mailLinkWrapper}>
                  <a href="mailto:support@vitaliv.us"
                     rel="noreferrer"
                     className={styles.mailLink}
                     target="_blank">
                    support@vitaliv.us
                  </a>
                </div>

              </div>
          }
        </div>
      </nav>
    </header>
  )
}
